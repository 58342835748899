import React, { useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { useIsInViewport, useWindowSize } from "../../../hooks";
import "./AboutUs.scss";

const slickSettings = {
  dots: false,
  arrows: false,
  autoplay: true,
  autoplaySpeed: 3000,
  infinite: true,
  speed: 400,
  slidesToShow: 4,
  // variableWidth: true,
  // variableWidth: "100%",
  // centerMode: true,
};

const AboutUs = ({ title, featuredIn }) => {
  const windowSize = useWindowSize();
  const isInViewportThreshold = windowSize.width > 768 ? 0.5 : 0.2;
  const sectionRef = useRef(null);
  const isInViewport = useIsInViewport(sectionRef, isInViewportThreshold);
  const [showAnimation, setShowAnimation] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect( () => {
    checkIsMobile(checkIsMobile());
  }, [])

  useEffect(() => {
    if (isInViewport) {
      setTimeout(() => setShowAnimation(isInViewport), 50);
    }
  }, [isInViewport]);

  useEffect(() => {
    setIsMobile(checkIsMobile());
  }, [windowSize]);

  function checkIsMobile() {
    const mobileBreakpoint = 768;
    return windowSize.width <= mobileBreakpoint;
  }

  return (
    <section
      className={`section section--aboutUs${showAnimation ? " onScreen" : ""}`}
      ref={sectionRef}
    >
      <div className="section__innerWrapper">
        <article className="overDeco">
          <h3 className="center animateIn">{title}</h3>
        </article>
        {!isMobile? 
        <Slider {...slickSettings}>
          {featuredIn?.map((feat, index) => (
            <div className="slide-wrapper" key={index}>
              <img
                src={`${process.env.REACT_APP_IMG_URL}/${feat.img}`}
                alt=""
                onClick={() => window.open(feat.link)}
              />
            </div>
          ))}
        </Slider>
        : featuredIn?.map((feat, index) => (
          <div key={index}>
              <div className="slide-wrapper">
                <img
                  src={`${process.env.REACT_APP_IMG_URL}/${feat.img}`}
                  alt=""
                  onClick={() => window.open(feat.link)}
                />
              </div>
            </div>
        )) 
        }
      </div>
    </section>
  );
};

export default AboutUs;
