import React, { useEffect, useRef, useState } from "react";
import { useIsInViewport, useWindowSize } from "../../../hooks";
import { Box, Typography, Paper} from "@mui/material";
import "./Fundings.scss";

const Fundings = ({ title, bubbles }) => {
  const windowSize = useWindowSize();
  const isInViewportThreshold = 0; //!isMobile() ? 0.5 : 0; // value of 0 fixes the mobile

  const sectionRef = useRef(null);
  const isInViewport = useIsInViewport(sectionRef, isInViewportThreshold);
  const [showAnimation, setShowAnimation] = useState(false);

  useEffect(() => {
    if (isInViewport) {
      setTimeout(() => setShowAnimation(isInViewport), 50);
    }
  }, [isInViewport]);

  function isMobile() {
    const mobileBreakpoint = 768;
    return windowSize.width <= mobileBreakpoint;
  }

  return (
    <section
      className={`section section--ourFundings${
        showAnimation ? " onScreen" : ""
      }`}
      ref={sectionRef}
    >
      <div className="section__innerWrapper">
        <h2 className="center animateIn">
          <a className="anchor" name="how-it-works">
            HOW IT WORKS
          </a>
        </h2>
        <h3 className="center animateIn">{title}</h3>

        <Box className="cards-fundings">
          {bubbles?.map((section, index) => (
            <Paper key={index} elevation={3} className={`card-fundings ${index === 0 ? 'left' : index === 1 ? 'center' : 'right'}`}>
              
              {/* Bubble (question) */}
              <Box className={`bubble-fundings ${index === 0 ? 'left' : index === 1 ? 'center' : 'right'}`}>
                <Typography variant="h6" className="bubble-fundings-text">
                  {section.bubble.toUpperCase()}
                </Typography>
              </Box>

              {/* Points */}
              <ul className="points-fundings"  >
                {section.points.map((point, i) => (
                  <li key={i} className="point-fundings-item" dangerouslySetInnerHTML={{ __html: point.replace(/_(.*?)_/g, '<span class="point-fundings-item-colored" >$1</span>')}} />
                ))}
              </ul>
            </Paper>
          ))}
        </Box>

        <a href="#apply-now" title="Apply now" className="apply-now-button">
          <button className="button primary">Apply now</button>
        </a>
      </div>
    </section>
  );
};

export default Fundings;
